import { EventEmitter } from '@angular/core';
import { County } from './county';
var CountySelectorComponent = /** @class */ (function () {
    function CountySelectorComponent(countyService) {
        this.countyService = countyService;
        this.countyChange = new EventEmitter();
        this.countyIdChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.newCounty = new County();
        this.autoCompleteApiService = countyService;
    }
    Object.defineProperty(CountySelectorComponent.prototype, "countyId", {
        get: function () {
            return this.county ? this.county.id : null;
        },
        set: function (countyId) {
            var _this = this;
            if (countyId && (!this.county || countyId !== this.county.id)) {
                console.log(this.county);
                console.log(this);
                this.loading = true;
                this.loadingChange.emit(this.loading);
                this.countyService.getObject(countyId).subscribe(function (county) {
                    _this.loading = false;
                    _this.county = county;
                    _this.countyChange.emit(_this.county);
                    _this.loadingChange.emit(_this.loading);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    CountySelectorComponent.prototype.formatButtonText = function (item) {
        return item.county + ', ' + item.state;
    };
    CountySelectorComponent.prototype.onSelect = function (model) {
        this.county = model;
        this.countyChange.emit(this.county);
        this.countyIdChange.emit(this.countyId);
    };
    CountySelectorComponent.prototype.createItem = function (str) {
        var parts = str.split(',');
        this.newCounty.county = parts[0] ? parts[0].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
        this.newCounty.state = parts[1] ? parts[1].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
        this.createFormModal.open();
    };
    CountySelectorComponent.prototype.onCountyCreate = function (county) {
        this.newCounty = new County();
        this.createFormModal.close();
        this.onSelect(county);
    };
    CountySelectorComponent.prototype.onCountyCreateCancel = function () {
        this.createFormModal.close();
    };
    return CountySelectorComponent;
}());
export { CountySelectorComponent };
