import { EventEmitter } from '@angular/core';
var SeriesSelectorComponent = /** @class */ (function () {
    function SeriesSelectorComponent(seriesService) {
        this.seriesService = seriesService;
        this.seriesChange = new EventEmitter();
        this.seriesIdChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.autoCompleteApiService = seriesService;
    }
    Object.defineProperty(SeriesSelectorComponent.prototype, "seriesId", {
        get: function () {
            return this.series ? this.series.id : null;
        },
        set: function (seriesId) {
            var _this = this;
            if (seriesId && (!this.series || seriesId != this.series.id)) {
                console.log(this.series);
                console.log(this);
                this.loading = true;
                this.loadingChange.emit(this.loading);
                this.seriesService.getObject(seriesId).subscribe(function (series) {
                    _this.loading = false;
                    _this.series = series;
                    _this.seriesChange.emit(_this.series);
                    _this.loadingChange.emit(_this.loading);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    SeriesSelectorComponent.prototype.formatButtonText = function (item) {
        return item.id_public + ' ' + (item.name ? item.name : '');
    };
    SeriesSelectorComponent.prototype.onSelect = function (model) {
        this.series = model;
        this.seriesChange.emit(this.series);
        this.seriesIdChange.emit(this.seriesId);
    };
    return SeriesSelectorComponent;
}());
export { SeriesSelectorComponent };
