import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';
import { map } from 'rxjs/operators';
var AuditQuestionService = /** @class */ (function (_super) {
    tslib_1.__extends(AuditQuestionService, _super);
    function AuditQuestionService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/audit_questions';
        return _this;
    }
    AuditQuestionService.prototype.getDraftDiff = function (options) {
        var _this = this;
        return this._apiService.get('/audit_questions/draft_diff', options).pipe(map(function (res) {
            return _this.handleCollectionResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    AuditQuestionService.prototype.applyDraft = function (options) {
        var _this = this;
        return this._apiService.post('/audit_questions/apply_draft', null, options).pipe(map(function (res) {
            return _this.handleCollectionResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    AuditQuestionService.prototype.resetDraft = function (options) {
        var _this = this;
        return this._apiService.post('/audit_questions/reset_draft', null, options).pipe(map(function (res) {
            return _this.handleCollectionResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    return AuditQuestionService;
}(ApiResourceService));
export { AuditQuestionService };
