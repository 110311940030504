import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';
var CountyService = /** @class */ (function (_super) {
    tslib_1.__extends(CountyService, _super);
    function CountyService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/counties';
        return _this;
    }
    return CountyService;
}(ApiResourceService));
export { CountyService };
