import { EventEmitter } from '@angular/core';
import { City } from './city';
var CitySelectorComponent = /** @class */ (function () {
    function CitySelectorComponent(cityService) {
        this.cityService = cityService;
        this.cityChange = new EventEmitter();
        this.cityIdChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.newCity = new City();
        this.autoCompleteApiService = cityService;
    }
    Object.defineProperty(CitySelectorComponent.prototype, "cityId", {
        get: function () {
            return this.city ? this.city.id : null;
        },
        set: function (cityId) {
            var _this = this;
            if (cityId && (!this.city || cityId !== this.city.id)) {
                this.loading = true;
                this.loadingChange.emit(this.loading);
                this.cityService.getObject(cityId).subscribe(function (city) {
                    _this.loading = false;
                    _this.city = city;
                    _this.cityChange.emit(_this.city);
                    _this.loadingChange.emit(_this.loading);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    CitySelectorComponent.prototype.formatButtonText = function (item) {
        return item.city + ', ' + item.state;
    };
    CitySelectorComponent.prototype.onSelect = function (model) {
        this.city = model;
        this.cityChange.emit(this.city);
        this.cityIdChange.emit(this.cityId);
    };
    CitySelectorComponent.prototype.createItem = function (str) {
        var parts = str.split(',');
        this.newCity.city = parts[0] ? parts[0].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
        this.newCity.state = parts[1] ? parts[1].replace(/^\s\s*/, '').replace(/\s\s*$/, '') : '';
        this.createFormModal.open();
    };
    CitySelectorComponent.prototype.onCityCreate = function (city) {
        this.newCity = new City();
        this.createFormModal.close();
        this.onSelect(city);
    };
    CitySelectorComponent.prototype.onCityCreateCancel = function () {
        this.createFormModal.close();
    };
    return CitySelectorComponent;
}());
export { CitySelectorComponent };
