import { EventEmitter, OnInit } from '@angular/core';
import { AuditQuestion } from '../../core/audit-question/audit-question';
var AdminAuditQuestionFormComponent = /** @class */ (function () {
    function AdminAuditQuestionFormComponent(auditQuestionService) {
        this.auditQuestionService = auditQuestionService;
        this.auditQuestion = new AuditQuestion();
        this.saved = new EventEmitter();
        this.canceled = new EventEmitter();
        this.loaders = {};
        this.loading = false;
        this.saving = false;
        this.errors = {};
    }
    Object.defineProperty(AdminAuditQuestionFormComponent.prototype, "jurisdictionType", {
        set: function (input) {
            this.jurisdiction_type = input;
        },
        enumerable: true,
        configurable: true
    });
    AdminAuditQuestionFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.auditQuestionId) {
            this.auditQuestionService.getObject(this.auditQuestionId).subscribe(function (auditQuestion) {
                _this.auditQuestion = auditQuestion;
                _this.loading = false;
                _this.initQuestion();
            });
        }
        else if (this.auditQuestion && this.auditQuestion.id) {
            this.auditQuestionId = this.auditQuestion.id;
        }
        this.initQuestion();
    };
    AdminAuditQuestionFormComponent.prototype.initQuestion = function () {
        if (this.jurisdiction_type) {
            // do nothing
        }
        else if (this.auditQuestion.city_id) {
            this.jurisdiction_type = 'city';
        }
        else if (this.auditQuestion.county_id) {
            this.jurisdiction_type = 'county';
        }
        else {
            this.jurisdiction_type = 'state';
        }
    };
    AdminAuditQuestionFormComponent.prototype.save = function () {
        var _this = this;
        console.log('saving');
        this.saving = true;
        this.errors = {};
        if (this.jurisdiction_type === 'state') {
            this.auditQuestion.county = null;
            this.auditQuestion.county_id = null;
            this.auditQuestion.city = null;
            this.auditQuestion.city_id = null;
            this.auditQuestion.is_universal = false;
        }
        else if (this.jurisdiction_type === 'county') {
            this.auditQuestion.city = null;
            this.auditQuestion.city_id = null;
            this.auditQuestion.state = null;
            this.auditQuestion.is_universal = false;
        }
        else if (this.jurisdiction_type === 'city') {
            this.auditQuestion.county = null;
            this.auditQuestion.county_id = null;
            this.auditQuestion.state = null;
            this.auditQuestion.is_universal = false;
        }
        else if (this.jurisdiction_type === 'universal') {
            this.auditQuestion.county = null;
            this.auditQuestion.county_id = null;
            this.auditQuestion.state = null;
            this.auditQuestion.city = null;
            this.auditQuestion.is_universal = true;
        }
        this.auditQuestionService.save(this.auditQuestion).subscribe(function (auditQuestion) {
            _this.auditQuestion = auditQuestion;
            _this.auditQuestionId = auditQuestion.id;
            _this.saving = false;
            _this.saved.emit(auditQuestion);
        }, function (error) {
            _this.errors = error.messages;
            _this.saving = false;
        });
    };
    AdminAuditQuestionFormComponent.prototype.cancel = function () {
        this.canceled.emit();
    };
    AdminAuditQuestionFormComponent.prototype.formatText = function (input) {
        return input.county;
    };
    return AdminAuditQuestionFormComponent;
}());
export { AdminAuditQuestionFormComponent };
