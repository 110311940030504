import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';
var ClientUserService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientUserService, _super);
    function ClientUserService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/client_users';
        return _this;
    }
    return ClientUserService;
}(ApiResourceService));
export { ClientUserService };
