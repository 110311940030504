import { EventEmitter } from '@angular/core';
var InfractionTypeSelectorComponent = /** @class */ (function () {
    function InfractionTypeSelectorComponent(infractionTypeService) {
        this.infractionTypeService = infractionTypeService;
        this.infractionTypeChange = new EventEmitter();
        this.infractionTypeIdChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.autoCompleteApiService = infractionTypeService;
    }
    Object.defineProperty(InfractionTypeSelectorComponent.prototype, "infractionTypeId", {
        get: function () {
            return this.infractionType ? this.infractionType.id : null;
        },
        set: function (infractionTypeId) {
            var _this = this;
            if (infractionTypeId && (!this.infractionType || infractionTypeId != this.infractionType.id)) {
                console.log(this.infractionType);
                console.log(this);
                this.loading = true;
                this.loadingChange.emit(this.loading);
                this.infractionTypeService.getObject(infractionTypeId).subscribe(function (infractionType) {
                    _this.loading = false;
                    _this.infractionType = infractionType;
                    _this.infractionTypeChange.emit(_this.infractionType);
                    _this.loadingChange.emit(_this.loading);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    InfractionTypeSelectorComponent.prototype.formatButtonText = function (item) {
        return item.id_public + ' ' + (item.name ? item.name : '');
    };
    InfractionTypeSelectorComponent.prototype.onSelect = function (model) {
        this.infractionType = model;
        this.infractionTypeChange.emit(this.infractionType);
        this.infractionTypeIdChange.emit(this.infractionTypeId);
    };
    return InfractionTypeSelectorComponent;
}());
export { InfractionTypeSelectorComponent };
