import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResourceService } from '../api-resource.service';
var SeriesService = /** @class */ (function (_super) {
    tslib_1.__extends(SeriesService, _super);
    function SeriesService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/series';
        return _this;
    }
    SeriesService.prototype.softConstructor = function () {
        var _this = this;
        this.getCachedCollection().subscribe(function (types) { return _this._cachedCollection = types; });
    };
    SeriesService.prototype.getCachedCollection = function (options) {
        var _this = this;
        if (this._cachedCollection) {
            var observer_1;
            setTimeout(function () { return observer_1.next(_this._cachedCollection); });
            return new Observable(function (setObserver) { return observer_1 = setObserver; })
                .share();
        }
        else if (this._cachedSubscribe) {
            return this._cachedSubscribe;
        }
        else {
            this._cachedSubscribe = this.getCollection(options);
            return this._cachedSubscribe;
        }
    };
    return SeriesService;
}(ApiResourceService));
export { SeriesService };
