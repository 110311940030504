import { EventEmitter } from '@angular/core';
var QuestionTypeSelectorComponent = /** @class */ (function () {
    function QuestionTypeSelectorComponent(questionTypeService) {
        this.questionTypeService = questionTypeService;
        this.questionTypeChange = new EventEmitter();
        this.questionTypeIdChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.autoCompleteApiService = questionTypeService;
    }
    Object.defineProperty(QuestionTypeSelectorComponent.prototype, "questionTypeId", {
        get: function () {
            return this.questionType ? this.questionType.id : null;
        },
        set: function (questionTypeId) {
            var _this = this;
            if (questionTypeId && (!this.questionType || questionTypeId != this.questionType.id)) {
                this.loading = true;
                this.loadingChange.emit(this.loading);
                this.questionTypeService.getObject(questionTypeId).subscribe(function (questionType) {
                    _this.loading = false;
                    _this.questionType = questionType;
                    _this.questionTypeChange.emit(_this.questionType);
                    _this.loadingChange.emit(_this.loading);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    QuestionTypeSelectorComponent.prototype.formatButtonText = function (item) {
        return item.id_public + ' ' + (item.name ? item.name : '');
    };
    QuestionTypeSelectorComponent.prototype.onSelect = function (model) {
        this.questionType = model;
        this.questionTypeChange.emit(this.questionType);
        this.questionTypeIdChange.emit(this.questionTypeId);
    };
    return QuestionTypeSelectorComponent;
}());
export { QuestionTypeSelectorComponent };
