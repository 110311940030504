import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';
var ClientService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientService, _super);
    function ClientService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/clients';
        return _this;
    }
    return ClientService;
}(ApiResourceService));
export { ClientService };
