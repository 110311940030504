import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ApiResourceService } from '../api-resource.service';
import { map } from 'rxjs/operators';
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/users';
        return _this;
    }
    UserService.prototype.getAccessOriginAggregate = function (options) {
        var _this = this;
        return this._apiService.get('/user_access_origins/aggregate', options).pipe(map(function (res) {
            return _this.handleCollectionResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    UserService.prototype.sendPasswordReset = function (email) {
        var _this = this;
        return this._apiService.post('/users/forgot_password', { email: email }).pipe(map(function (res) {
            return _this.handleCollectionResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    UserService.prototype.resetPassword = function (email, token, newPassword) {
        var _this = this;
        return this._apiService.post('/users/reset_password', { email: email, new_password: newPassword, token: token }).pipe(map(function (res) {
            return _this.handleCollectionResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    return UserService;
}(ApiResourceService));
export { UserService };
