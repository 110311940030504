import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiResourceService } from '../api-resource.service';
var LicenseService = /** @class */ (function (_super) {
    tslib_1.__extends(LicenseService, _super);
    function LicenseService(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this._apiPath = '/licenses';
        return _this;
    }
    LicenseService.prototype.subscriptionPayment = function (model, options) {
        var _this = this;
        var request = this._apiService.post(this._apiPath + '/' + model.id + '/subscription', model, options);
        return request.pipe(map(function (res) {
            return _this.handleObjectResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    LicenseService.prototype.cancelSubscription = function (model, options) {
        var _this = this;
        var request = this._apiService.delete(this._apiPath + '/' + model.id + '/subscription', options);
        return request.pipe(map(function (res) {
            return _this.handleObjectResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    LicenseService.prototype.questionCount = function (model, options) {
        var _this = this;
        var request = this._apiService.get(this._apiPath + '/' + model.id + '/question_count', options);
        return request.pipe(map(function (res) {
            return _this.handleObjectResponse(res);
        }, function (error) {
            return _this.handleBasicError(error);
        }));
    };
    return LicenseService;
}(ApiResourceService));
export { LicenseService };
