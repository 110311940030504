import { EventEmitter } from '@angular/core';
var ClientSelectorComponent = /** @class */ (function () {
    function ClientSelectorComponent(clientService) {
        this.clientService = clientService;
        this.clientChange = new EventEmitter();
        this.clientIdChange = new EventEmitter();
        this.loadingChange = new EventEmitter();
        this.autoCompleteApiService = clientService;
    }
    Object.defineProperty(ClientSelectorComponent.prototype, "clientId", {
        get: function () {
            return this.client ? this.client.id : null;
        },
        set: function (clientId) {
            var _this = this;
            if (clientId && (!this.client || clientId != this.client.id)) {
                console.log(this.client);
                console.log(this);
                this.loading = true;
                this.loadingChange.emit(this.loading);
                this.clientService.getObject(clientId).subscribe(function (client) {
                    _this.loading = false;
                    _this.client = client;
                    _this.clientChange.emit(_this.client);
                    _this.loadingChange.emit(_this.loading);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    ClientSelectorComponent.prototype.formatButtonText = function (item) {
        return item.client_name;
    };
    ClientSelectorComponent.prototype.onSelect = function (model) {
        this.client = model;
        this.clientChange.emit(this.client);
        this.clientIdChange.emit(this.clientId);
    };
    return ClientSelectorComponent;
}());
export { ClientSelectorComponent };
